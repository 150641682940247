import React from 'react';
import { Link } from 'react-router-dom';

const Header: React.FC = () => {
  return (
    <header
      className="sticky top-0 shadow-md z-50"
      style={{
        backgroundColor: 'rgba(255, 255, 255, 0.6)', // %60 şeffaf beyaz
        backdropFilter: 'blur(10px)', // Arka planı bulanık yapma
        WebkitBackdropFilter: 'blur(10px)', // Safari için destek
      }}
    >
      <div className="container mx-auto flex justify-between items-center px-4 py-4">
        {/* Logo */}
        <Link to="/">
          <img src="/images/logo/logo-2.png" alt="MoveAgain Logo" className="w-56 h-auto" />
        </Link>
        
        {/* Menü ve Çağrı Bilgileri */}
        <div className="flex items-center space-x-6">
          <div className="text-sm">
            <p>CALL US FOR FREE</p>
            <p className="font-bold">058 715 11 66</p>
          </div>
          <button className="bg-gray-200 text-gray-700 px-3 py-1 rounded">EN</button>
        </div>
      </div>
    </header>
  );
};

export default Header;