import React, { createContext, useContext, useState, ReactNode } from "react";

interface Address {
  street: string;
  postcode: string;
  location: string;
}

interface MoveAddressContextProps {
  moveOutAddress: Address;
  moveInAddress: Address;
  setMoveOutAddress: (address: Address) => void;
  setMoveInAddress: (address: Address) => void;
}

const MoveAddressContext = createContext<MoveAddressContextProps | undefined>(undefined);

export const MoveAddressProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [moveOutAddress, setMoveOutAddress] = useState<Address>({
    street: "",
    postcode: "",
    location: "",
  });

  const [moveInAddress, setMoveInAddress] = useState<Address>({
    street: "",
    postcode: "",
    location: "",
  });

  return (
    <MoveAddressContext.Provider value={{ moveOutAddress, moveInAddress, setMoveOutAddress, setMoveInAddress }}>
      {children}
    </MoveAddressContext.Provider>
  );
};

export const useMoveAddress = () => {
  const context = useContext(MoveAddressContext);
  if (!context) {
    throw new Error("useMoveAddress must be used within a MoveAddressProvider");
  }
  return context;
};