import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import { useLocation } from "react-router-dom";
import { useMoveAddress } from "../contexts/MoveAdressContext";
import { useBuildingContext } from "../contexts/BuildingDetailContext";

function MovingItemsDetail() {
  const categories = [
    { id: 1, name: "BEDROOM" },
    { id: 2, name: "LIVING ROOM" },
    { id: 3, name: "DINING ROOM" },
    { id: 4, name: "KITCHEN" },
    { id: 5, name: "BATHROOM" },
    { id: 6, name: "STORAGE ROOM" },
    { id: 7, name: "GARDEN/BALCONY" },
    { id: 8, name: "DECORATION" },
    { id: 9, name: "OTHER" },
    { id: 10, name: "ALL ITEMS" },
  ];

  const items = [
    { id: 1, name: "Double bed", icon: "./images/icons/bed.png", categoryId: 1 },
    { id: 2, name: "Single bed", icon: "./images/icons/bed.png", categoryId: 1 },
    { id: 3, name: "Box spring bed", icon: "./images/icons/boxedbed.png", categoryId: 1 },
    { id: 4, name: "Mattress", icon: "/icons/mattress.svg", categoryId: 1 },
    { id: 5, name: "Commode", icon: "/icons/commode.svg", categoryId: 1 },
    { id: 6, name: "Desk", icon: "/icons/desk.svg", categoryId: 1 },
    { id: 7, name: "Office chair", icon: "/icons/office-chair.svg", categoryId: 1 },
    { id: 8, name: "Night table", icon: "/icons/night-table.svg", categoryId: 1 },
    { id: 9, name: "Wardrobe 1-2 doors", icon: "/icons/wardrobe-small.svg", categoryId: 1 },
    { id: 10, name: "Wardrobe 3-4 doors", icon: "/icons/wardrobe-medium.svg", categoryId: 1 },
    { id: 11, name: "Wardrobe 5-6 doors", icon: "/icons/wardrobe-large.svg", categoryId: 1 },
  ];

  const [selectedCategory, setSelectedCategory] = useState<number | null>(categories[0]?.id || null);
  const [quantities, setQuantities] = useState<{ [key: number]: number }>({});
  const [totalItems, setTotalItems] = useState(0);

  const { moveOutAddress, moveInAddress } = useMoveAddress();
  const { moveOutBuilding, moveInBuilding } = useBuildingContext();

  const handleCategoryClick = (categoryId: number) => {
    setSelectedCategory(categoryId);
  };

  const handleQuantityChange = (itemId: number, amount: number) => {
    setQuantities((prev) => {
      const updatedQuantity = Math.max(0, (prev[itemId] || 0) + amount);
      return { ...prev, [itemId]: updatedQuantity };
    });
  };

  useEffect(() => {
    const total = Object.values(quantities).reduce((sum, qty) => sum + qty, 0);
    setTotalItems(total);
  }, [quantities]);

  return (
    <div className="bg-gray-100 min-h-screen">
      <Header />

      {/* Header Banner */}
      <div className="bg-white text-purple-700 font-bold text-center py-4 mb-4 mx-5 rounded-lg shadow-md mt-5">
        <span className="flex items-center justify-center">
          <img src="/images/integrity.png" alt="icon" className="w-10 h-10 mr-2" />
          FIXED PRICE AND 100% INSURANCE - Our partner companies are trusted and hand-picked!
        </span>
      </div>

      {/* Main Container */}
      <div className="grid grid-cols-12 gap-4 px-5">
        {/* Sol Kolon */}
        <div className="col-span-3 space-y-3 mb-4 ml-8 mr-8">
          <div className="mx-auto bg-white border border-gray-200 rounded-lg shadow-md">
            <div className="bg-green-400 text-white text-lg font-semibold p-3 rounded-t-lg">
              Category
            </div>
            <div className="p-4">
              <ul className="space-y-2 text-gray-700">
                {categories.map((category) => (
                  <li
                    key={category.id}
                    className={`cursor-pointer ${
                      selectedCategory === category.id ? "text-red-600 font-bold" : ""
                    }`}
                    onClick={() => handleCategoryClick(category.id)}
                  >
                    {category.name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        {/* Orta Kolon */}
        <div className="col-span-6 bg-white border border-gray-200 rounded-lg shadow-md mb-4">
          <div className="bg-violet-600 text-white text-lg font-semibold p-4 rounded-t-lg">
            Items in {categories.find((cat) => cat.id === selectedCategory)?.name || "Category"}
          </div>
          <div className="p-4">
            {items
              .filter((item) => item.categoryId === selectedCategory)
              .map((item) => (
                <div key={item.id} className="flex items-center justify-between py-2 border-b">
                  <div className="flex items-center gap-4">
                    <img src={item.icon} alt={item.name} className="w-20 h-20" />
                    <span className="text-gray-700">{item.name}</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <button
                      className="text-gray-500 bg-gray-100 px-2 py-1 rounded"
                      onClick={() => handleQuantityChange(item.id, -1)}
                    >
                      -
                    </button>
                    <input
                      type="text"
                      value={quantities[item.id] || 0}
                      readOnly
                      className="w-14 h-14 text-center border rounded border-gray-300"
                    />
                    <button
                      className="text-gray-500 bg-gray-100 px-2 py-1 rounded"
                      onClick={() => handleQuantityChange(item.id, 1)}
                    >
                      +
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>

        {/* Sağ Kolon */}
        <div className="col-span-3 space-y-3 mb-4 mr-8">
          <div className="max-w-sm mx-auto bg-white border border-gray-200 rounded-lg shadow-md">
            <div className="bg-green-600 text-white text-lg font-semibold p-3 rounded-t-lg">
              Move Out Address
            </div>
            <div className="p-4">
              <p>Street: {moveOutAddress?.street}</p>
              <p>Postcode: {moveOutAddress?.postcode}</p>
              <p>Location: {moveOutAddress?.location}</p>
            </div>
          </div>
          <div className="max-w-sm mx-auto bg-white border border-gray-200 rounded-lg shadow-md">
            <div className="bg-green-600 text-white text-lg font-semibold p-3 rounded-t-lg">
              Move Out Address
            </div>
            <div className="p-4">
              <p>Street: {moveOutAddress?.street}</p>
              <p>Postcode: {moveOutAddress?.postcode}</p>
              <p>Location: {moveOutAddress?.location}</p>
            </div>
          </div>
          <div className="max-w-sm mx-auto bg-white border border-gray-200 rounded-lg shadow-md">
            <div className="bg-green-600 text-white text-lg font-semibold p-3 rounded-t-lg">
              Move Out Address
            </div>
            <div className="p-4">
              <p>Street: {moveOutAddress?.street}</p>
              <p>Postcode: {moveOutAddress?.postcode}</p>
              <p>Location: {moveOutAddress?.location}</p>
            </div>
          </div>
          <div className="max-w-sm mx-auto bg-white border border-gray-200 rounded-lg shadow-md">
            <div className="bg-green-600 text-white text-lg font-semibold p-3 rounded-t-lg">
              Move Out Address
            </div>
            <div className="p-4">
              <p>Street: {moveOutAddress?.street}</p>
              <p>Postcode: {moveOutAddress?.postcode}</p>
              <p>Location: {moveOutAddress?.location}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MovingItemsDetail;