import React, { createContext, useContext, useState, ReactNode } from "react";

export interface BuildingDetails {
  type: string;
  floor: string;
  elevator: string;
  bagCount?: number;
  notes: string;
}

interface BuildingContextProps {
  moveOutBuilding: BuildingDetails;
  moveInBuilding: BuildingDetails;
  setMoveOutBuilding: React.Dispatch<React.SetStateAction<BuildingDetails>>;
  setMoveInBuilding: (details: BuildingDetails) => void;
}

const BuildingContext = createContext<BuildingContextProps | undefined>(undefined);

export const BuildingProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [moveOutBuilding, setMoveOutBuilding] = useState<BuildingDetails>({
    type: "",
    floor: "",
    elevator: "",
    bagCount: 25,
    notes: "",
  });

  const [moveInBuilding, setMoveInBuilding] = useState<BuildingDetails>({
    type: "",
    floor: "",
    elevator: "",
    notes: "",
  });

  return (
    <BuildingContext.Provider value={{ moveOutBuilding, moveInBuilding, setMoveOutBuilding, setMoveInBuilding }}>
      {children}
    </BuildingContext.Provider>
  );
};

export const useBuildingContext = () => {
  const context = useContext(BuildingContext);
  if (!context) {
    throw new Error("useBuildingContext must be used within a BuildingProvider");
  }
  return context;
};