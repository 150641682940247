import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const HeaderMain: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header
      className={`fixed top-0 left-0 w-full z-50 transition-colors duration-300 ${
        isScrolled ? 'bg-white' : 'bg-transparent'
      }`}
      style={{
        backgroundColor: isScrolled ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.25)', // More transparent when not scrolled
        backdropFilter: isScrolled ? 'none' : 'blur(0px)', // Arka planı bulanık yapma
        WebkitBackdropFilter: isScrolled ? 'none' : 'blur(10px)', // Safari için destek
      }}
    >
      <div className="container mx-auto flex justify-between items-center px-4 py-4">
        {/* Logo */}
        <Link to="/">
          <img src="/images/logo/logo-2.png" alt="MoveAgain Logo" className="w-56 h-auto" />
        </Link>
        
        {/* Menü ve Çağrı Bilgileri */}
        <div className="flex items-center space-x-6">
          <div className="text-sm">
            <p className="font-bold purple-bold-title">CALL US FOR FREE</p>
            <p className="font-bold purple-bold-title">058 715 11 66</p>
          </div>
          <button className="bg-gray-200 text-gray-700 px-3 py-1 rounded">EN</button>
        </div>
      </div>
    </header>
  );
};

export default HeaderMain;